(_ => {
    const buttons = [].slice.call(document.getElementsByClassName('donation-link')),
        form = document.getElementById('pay_now_form_13b752da0d');

    if (buttons) {
        buttons.forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                form.submit();
            });
        });
    }
})();