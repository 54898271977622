//import Promise from 'promise-polyfill';
//import 'whatwg-fetch';

(() => {
    const blogListing = document.getElementsByClassName('blog__listing')[0];
    if (blogListing) {
        //const headers = new Headers({
        //    'Content-Type': 'text/plain',
        //    'X-Custom-Header': 'ProcessThisImmediately',
        //    'Access-Control-Allow-Origin': window.location.href,
        //    'Vary':'Origin'
        //});

        //// https://jsonplaceholder.typicode.com/posts/

        //fetch('https://medium.com/@thebrianemory/latest?format=json', {
        //    method: 'GET',
        //    headers,
        //    mode: 'cors',
        //})
        //.then(response => response.text()) // data is altered json. request for plain text
        //.then(text => console.log(text))
    }
})();