const videoTranscriptToggle = [].slice.call(document.getElementsByClassName('video-transcript__toggle'));

if (videoTranscriptToggle.length) {
    (_ => {
        for (var i = 0; i < videoTranscriptToggle.length; i++) {
            const self = videoTranscriptToggle[i];
            const toggleInnerContent = self.getElementsByClassName('video-transcript__text')[0];
            const originalText = self.getElementsByClassName('video-transcript__text')[0].textContent;
            
            videoTranscriptToggle[i].addEventListener('click', e => {
                self.classList.toggle('video-transcript__toggle--active');
                if (self.classList.contains('video-transcript__toggle--active')) {
                    toggleInnerContent.textContent = 'Close';
                } else {
                    toggleInnerContent.textContent = originalText;
                }
            });
        }
    })();
}