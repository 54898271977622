import 'promise-polyfill/src/polyfill';
import axios from 'axios';

(() => {
    const newsletter = [].slice.call(document.getElementsByClassName('newsletter-interstitial'));
    
    if (newsletter.length) {
        const form = newsletter[0].getElementsByClassName('newsletter-interstitial__form')[0],
            formButton = newsletter[0].getElementsByClassName('newsletter-interstitial__submit')[0],
            formInput = newsletter[0].getElementsByClassName('newsletter-interstitial__email')[0],
            formInputName = newsletter[0].getElementsByClassName('newsletter-interstitial__fieldName')[0],
            formErrorMsg = newsletter[0].getElementsByClassName('newsletter-interstitial__error')[0],
            successMsg = newsletter[0].getElementsByClassName('newsletter-interstitial__success')[0],
            postURL = 'https://visitor2.constantcontact.com/api/signup?ca=de665588-4ed5-45a9-80ad-b9d945c6a2d6',
            regexTest = /^[a-zA-Z0-9!#$&_*?^~-]+(\.?[a-zA-Z0-9!#$&_*?^~-])*@([a-zA-Z0-9!#$&_*?^~-]+[a-z0-9A-Z]+([a-zA-Z0-9-]*)\.)+[a-zA-Z]+$/;

        formInput.addEventListener('focus', e => {
            if (!formErrorMsg.classList.contains('newsletter-interstitial__fieldName--active')) formInputName.classList.add('newsletter-interstitial__fieldName--active');
        });

        newsletter[0].addEventListener('click', e => {
            if (e.target !== formInput) {
                if (formInput.value.length === 0) formInputName.classList.remove('newsletter-interstitial__fieldName--active');
            }
        });

        formInput.addEventListener('change', e => {
            if (formInput.value.length === 0) formInputName.classList.remove('newsletter-interstitial__fieldName--active');
        });

        formButton.addEventListener('click', e => {
            e.preventDefault();

            if (formInput.value.length > 0 && regexTest.test(formInput.value)) {
                formErrorMsg.classList.remove('newsletter-interstitial__error--show');

                axios({
                    method: 'post',
                    url: postURL,
                    data: {
                        email: formInput.value,
                    }
                }).then(function (response) {
                    successMsg.classList.add('newsletter-interstitial__success--show');
                    form.classList.add('newsletter-interstitial__form--hide');
                }).catch(function (error) {
                    console.error(error);
                });

            } else {
                formErrorMsg.classList.add('newsletter-interstitial__error--show');
            }
        })
    }
})();