(() => {
    if (window.matchMedia('(max-width: 48em)').matches) {
        const sitemapContainer = document.getElementsByClassName('sitemap__menu')[0],
            parentLinks = sitemapContainer.querySelectorAll('.site-navigation__level--2 > .sitemap__menu__item');

        for (var i = 0; i < parentLinks.length; i++) {
            const subList = parentLinks[i].getElementsByTagName('ul')[0];

            if (subList) {
                const iconNode = document.createElement('span');

                iconNode.setAttribute('class', 'sitemap__icon');
                parentLinks[i].insertBefore(iconNode, subList);
            }


        }

        sitemapContainer.addEventListener('click', event => {
            const targetElement = event.target;
            if (targetElement.classList.contains('sitemap__icon')) {
                const parent = targetElement.parentNode;

                parent.classList.contains('sitemap__menu__item--open') ? parent.classList.remove('sitemap__menu__item--open') : parent.classList.add('sitemap__menu__item--open');
            };
        });
    }
})();